var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":_vm.modalTitle,"okText":"Save"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-spin',{attrs:{"tip":"Please wait...","spinning":_vm.loading}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Title"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'title',
                {
                  rules: [{ required: true, message: 'Title is required!' }],
                  initialValue: _vm.room.title,
                },
              ]),expression:"[\n                'title',\n                {\n                  rules: [{ required: true, message: 'Title is required!' }],\n                  initialValue: room.title,\n                },\n              ]"}],attrs:{"placeholder":"Title","auto-size":""}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Description"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'description',
                {
                  rules: [
                    { required: true, message: 'Description is required!' },
                  ],
                  initialValue: _vm.room.description,
                },
              ]),expression:"[\n                'description',\n                {\n                  rules: [\n                    { required: true, message: 'Description is required!' },\n                  ],\n                  initialValue: room.description,\n                },\n              ]"}],attrs:{"placeholder":"Description","rows":5}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":"Price"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'price',
                {
                  rules: [{ required: true, message: 'Price is required!' }],
                  initialValue: _vm.room.price,
                },
              ]),expression:"[\n                'price',\n                {\n                  rules: [{ required: true, message: 'Price is required!' }],\n                  initialValue: room.price,\n                },\n              ]"}],attrs:{"formatter":_vm.formattedPrice}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":"Pieces Count"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'pieces_count',
                {
                  rules: [
                    { required: true, message: 'Pieces Count is required!' },
                  ],
                  initialValue: _vm.room.price,
                },
              ]),expression:"[\n                'pieces_count',\n                {\n                  rules: [\n                    { required: true, message: 'Pieces Count is required!' },\n                  ],\n                  initialValue: room.price,\n                },\n              ]"}]})],1)],1)]),_c('p',{staticClass:"text-info"},[_vm._v("You can upload max 3 images for this room.")]),_c('a-upload',{attrs:{"multiple":"","list-type":"picture-card","accept":"image/*","defaultFileList":_vm.featuredImageData,"before-upload":_vm.beforeFeaturedImageUpload,"remove":_vm.handleRemove,"fileList":_vm.fileList},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[(_vm.fileList.length < 3)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("Upload Featured Images")])],1):_vm._e()]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }