<template>
  <div>
    <div class="service-header">
      <div class="font-size-36 font-weight-bold text-dark mb-4">Rooms</div>
      <a-button type="primary" @click="addRoom" v-if="canAddService"
        >Add</a-button
      >
    </div>

    <div class="row">
      <div v-if="roomsData.length">
        <div class="flex-wrapper">
          <shared-service-card-with-featured-images
            class="entity-card"
            v-for="(service, index) of roomsData"
            :key="index"
            :service="service"
            @editService="editRoom($event)"
            @removeService="deleteRoom($event)"
          />
        </div>
      </div>
      <div v-else class="text-center">
        <h4>No Services found</h4>
      </div>
    </div>

    <accommodation-room-service-modal
      v-if="showModal"
      serviceTitle="Room Name"
      :defaultRoom="defaultRoom"
      :showModal="showModal"
      @savedRoom="savedRoom"
      @closeModal="closeModal"
    />
  </div>
</template>
<script>
import AccommodationRoomServiceModal from "@/components/services/AccommodationRoomServiceModal";
import SharedServiceCardWithFeaturedImages from "@/components/services/SharedServiceCardWithFeaturedImages.vue";

import ProfileMixin from "@/mixins/Profile";
import { mapGetters } from "vuex";
import { DELETE_ACCOMMODATION_ROOM } from "@/store/actions";
import { mapActions } from "vuex";
import { get } from "lodash";

export default {
  mixins: [ProfileMixin],
  components: {
    AccommodationRoomServiceModal,
    SharedServiceCardWithFeaturedImages,
  },
  data() {
    return {
      roomsData: [],
      defaultRoom: {},
      showModal: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUserType", "currentUser"]),
    rooms() {
      return this.$store.getters[`${this.getUserAccountProfileModule}/rooms`];
    },
    permissions() {
      return this.currentUser ? this.currentUser.permissions : {};
    },
    canAddService() {
      return get(this.permissions, "can_add_service", false);
    },
    canUpdateService() {
      return get(this.permissions, "can_update_service", false);
    },
    canRemoveService() {
      return get(this.permissions, "can_remove_service", false);
    },
  },
  watch: {
    rooms: {
      handler(list) {
        if (Array.isArray(list)) {
          this.roomsData = list.reverse();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("accommodations", {
      deleteAccommodationRooms: DELETE_ACCOMMODATION_ROOM,
    }),
    addRoom() {
      this.showModal = true;
    },
    closeModal() {
      this.defaultRoom = {};
      this.showModal = false;
    },
    async savedRoom(room) {
      delete room.status;
      this.defaultRoom = {};
      this.showModal = false;
    },
    async deleteRoom(room) {
      await this.deleteAccommodationRooms(room._id);
      this.showModal = false;
    },
    editRoom(room) {
      this.defaultRoom = room;
      this.addRoom();
    },
  },
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.service-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-wrapper {
  display: flex;
  flex-direction: row;
  align-self: auto;
  flex-wrap: wrap;
}
.entity-card {
  margin-right: 25px;
}
</style>
